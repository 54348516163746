'use strict';
/*global WOW:false*/

const window_height = $(window).height();
const fix_height = 550 + 90;
let motion = 0;
if (window_height >= fix_height){
    motion = window_height - fix_height;
} else {
    motion = -(fix_height - window_height);
}

$(window).load(function () {
    if($('#js-loader, #js-loader-bg').length){
        $('#js-loader, #js-loader-bg').delay(900).animate({
            height: window_height - motion,
            opacity: 0
        }, 1500).fadeOut(10).queue(function() {
            // WOW
            new WOW().init({mobile: false});

            // メインコンテンツ表示
            $('header, main, footer').animate({
                opacity: 1
            }, 500);
        });
    }
});

$(function(){
    if ($('#js-loader-bg').length){
        $('header, main, footer').css({opacity: 0});
        $('#js-loader-bg').css({display: 'table', height: window_height});
    } else {
        // WOW
        new WOW().init({mobile: false});
    }

    // リストフォーカス
    const $js_hover_focus = $('.js_hover_focus');
    $js_hover_focus.hover(function(){
        $js_hover_focus.stop(true).animate({opacity: 0.4}, 100);
        $(this).stop(true).animate({opacity: 1}, 100);
    }, function(){
        $js_hover_focus.animate({opacity: 1}, 100);
    });
});